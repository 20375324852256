import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@monaco-editor/react";  // Updated import for Monaco Editor
import './Sandbox.css';  // Import CSS

const Sandbox = () => {
  const [library, setLibrary] = useState("p5.js");
  const [code, setCode] = useState("");
  const iframeRef = useRef(null);

  const libraries = {
    "p5.js": `function setup() {
      createCanvas(400, 400);
    }

    function draw() {
      background(220);
      ellipse(mouseX, mouseY, 50, 50);
    }`,
    "Three.js": `const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize( window.innerWidth, window.innerHeight );
    document.body.appendChild( renderer.domElement );

    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    const cube = new THREE.Mesh( geometry, material );
    scene.add( cube );

    camera.position.z = 5;

    function animate() {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    }

    animate();`,
  };

  useEffect(() => {
    setCode(libraries[library]);
  }, [library]);

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    if (iframeRef.current) {
      iframeRef.current.srcdoc = `
      <html>
        <head>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/${library}/1.4.0/${library}.min.js"></script>
        </head>
        <body>
          <script>${newCode}</script>
        </body>
      </html>`;
    }
  };

  return (
    <div className="sandbox-container">
      <div className="editor">
        <select value={library} onChange={(e) => setLibrary(e.target.value)}>
          <option value="p5.js">p5.js</option>
          <option value="Three.js">Three.js</option>
        </select>
        <Editor
          height="100vh"
          language="javascript"
          value={code}
          onChange={handleCodeChange}
        />
      </div>
      <div className="renderer">
        <iframe
          ref={iframeRef}
          title="output"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
};

export default Sandbox;