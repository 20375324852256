import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import GitHubLogo from './github-mark-white.svg'; // Import the SVG file
import Sandbox from './pages/Sandbox';
 // Import Sandbox component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Homepage Route */}
          <Route path="/" element={
            <>
              <h1>BaseMint</h1>
              <p>Coming Soon!</p>
              <footer>
                <a href="https://github.com/MeltedMindz/BaseMint" target="_blank" rel="noopener noreferrer">
                  <img src={GitHubLogo} alt="GitHub" className="github-logo" />
                </a>
              </footer>
            </>
          } />

          {/* Playground Route */}
          <Route path="/Sandbox" element={<Sandbox />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

